import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seoinfo from "../components/seoinfo/seoinfo"
import Hero from "../components/hero"
import ContactForm from "../components/contactForm/contactForm"

import AOS from "aos"

import Hero_desktop from "../images/Services1366.png"
import Hero_desktop1920 from "../images/Services1920.png"
import Hero_mobile from "../images/services-mobile.jpg"

import Payment1366 from "../images/payment1366.png"
import Payment1920 from "../images/payment1920.png"
import Payment2800 from "../images/payment2800.png"
import PaymentMobile from "../images/paymentMobile.png"

import plus from "../images/plus.svg"
import minus from "../images/minus.svg"

// Import SCSS file
// import '../styles/pages/sample-landing.scss';

import {
  SubTitle,
  UnderLineTitle,
  List,
  InternalWrapper,
} from "../styles/globals/styles.jsx"

import {
  PhysicalTherapySection,
  TextSection,
  TextDiv,
  SubTitleSection,
  TherapyAdressDiv,
  TherapyTitle,
  OccupationalTherapySection,
  SpeechTherapySection,
  MobileSection,
  TitleMobile,
  MoreInfoButton,
  DesktopSection,
  LargeText,
  GrayLine,
  DivTitleMobile,
  ContactFormContainer,
  ContactSection,
  PaymentDiv,
  PaymentImage1366,
  PaymentImage1920,
  PaymentImage2800,
  PaymentImageMobile,
  PaymentTextDiv,
  PaymentText,
  PaymentWrapper,
  PaymentTitle,
  PaymentItem,
  PaymentContent,
} from "../styles/pages/services.jsx"

const Services = () => {
  const [isPhysicalOpen, setPhysicalOpen] = useState(false)
  const [isOccupationalOpen, setOccupationalOpen] = useState(false)
  const [isSpeechOpen, setSpeechOpen] = useState(false)

  const images = {
    desktop: Hero_desktop,
    mobile: Hero_mobile,
    desktop1920: Hero_desktop1920,
  }

  useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      })
    },
    [],
    AOS
  )

  return (
    <Layout>
      <Seoinfo
        siteName={"Life Home Therapy"}
        titlePage={"Services"}
        description={
          "Life Home Therapy provides physical, occupational, and speech-language pathology services that intend to help patients regain independence and quality of life."
        }
        keywords={
          "therapy services, in-home services, in-home assistance, physical therapy, occupational therapy, speech-language pathology, home therapy, home therapy services"
        }
        type={"website"}
        url={"hometherapy.life/services"}
        imageFacebook={"seo/services.png"}
      />
      <Hero
        image={images}
        title="OUR SERVICES"
        text="We work together to help you heal."
        alt="Therapist helping an elderly man with arm stretching exercises"
        isFromServices
      />
      <div data-aos="fade" className="container">
        <PhysicalTherapySection>
          <MobileSection hasNoBorderAndPaddingBottom={isPhysicalOpen}>
            <DivTitleMobile>
              <TitleMobile>PHYSICAL THERAPY</TitleMobile>
              <UnderLineTitle displayNone={!isPhysicalOpen} />
            </DivTitleMobile>
            <MoreInfoButton
              isSmaller={isPhysicalOpen}
              src={isPhysicalOpen ? minus : plus}
              onClick={() => setPhysicalOpen(!isPhysicalOpen)}
            />
          </MobileSection>
          <GrayLine notDisplay={isPhysicalOpen} />
          <DesktopSection display={isPhysicalOpen}>
            <SubTitle displayNone={isPhysicalOpen} alignStart>
              PHYSICAL THERAPY
            </SubTitle>
            <UnderLineTitle displayNone={isPhysicalOpen} />
            <TextSection>
              <TextDiv hasMarginRight>
                The goal is set and clear: improve and restore mobility and
                muscle strength. Physical Therapy concentrates on helping with
                recovery from issues such as fall injuries, joint replacement
                surgeries, and stroke-related disabilities. We work towards
                making simple things of the daily routine like standing up,
                walking down the street, or going upstairs easy again. Therapy
                is provided in the comfort of the patient’s home.
                <br /> <br /> <br />
                <SubTitleSection>
                  Our Physical Therapy service help you learn how to restore
                  function through education on: <br /> <br />
                </SubTitleSection>
                <List hasMarginLeft>
                  <li>Balance coordination activities </li>
                  <li>Therapeutic exercises</li>
                  <li>
                    Home safety and best practices on moving around the home
                  </li>
                  <li>Fall prevention </li>
                  <li>Pain management</li>
                  <li>Instructions on how to best sit, stand and lie down</li>
                  <li>Safe and appropriate use of adaptive equipment </li>
                </List>
              </TextDiv>
              <TextDiv>
                <LargeText display={!isPhysicalOpen}>
                  Our physical therapists work with you to improve and restore
                  mobility and muscle strength. Skills are taught in the comfort
                  of your home by specially trained physical therapists and
                  physical therapy assistants. <br /> <br /> Our licensed
                  physical therapists are experienced in helping with recovery
                  from issues such as fall injuries, joint replacement surgeries
                  and stroke-related disabilities. Your individual treatment
                  plan is designed based on your needs, with the goal of
                  achieving the highest extent of recovery possible. <br />{" "}
                  <br />
                </LargeText>
                <TherapyAdressDiv>
                  <TherapyTitle>Physical Therapy addresses:</TherapyTitle>
                  <List hasTwoColumns>
                    <li>Falls Risk and Fear of Falling</li>
                    <li>Muscle Weakness</li>
                    <li>Dizziness Decreased Flexibility</li>
                    <li>Decreased Endurance</li>
                    <li>Difficulty Walking</li>
                    <li>Pain</li>
                    <li>Loss or Limitation in Function</li>
                  </List>
                </TherapyAdressDiv>
              </TextDiv>
            </TextSection>
          </DesktopSection>
        </PhysicalTherapySection>
      </div>
      <OccupationalTherapySection>
        <InternalWrapper data-aos="fade">
          <MobileSection hasNoBorderAndPaddingBottom={isOccupationalOpen}>
            <DivTitleMobile>
              <TitleMobile>OCCUPATIONAL THERAPY</TitleMobile>
              <UnderLineTitle displayNone={!isOccupationalOpen} />
            </DivTitleMobile>
            <MoreInfoButton
              isSmaller={isOccupationalOpen}
              src={isOccupationalOpen ? minus : plus}
              onClick={() => setOccupationalOpen(!isOccupationalOpen)}
            />
          </MobileSection>
          <GrayLine notDisplay={isOccupationalOpen} />
          <DesktopSection display={isOccupationalOpen}>
            <SubTitle displayNone={isOccupationalOpen} hasMarginTop alignStart>
              OCCUPATIONAL THERAPY
            </SubTitle>
            <UnderLineTitle displayNone={isOccupationalOpen} />
            <TextSection>
              <TextDiv hasMarginTop hasMarginRight>
                Occupational therapists focus on daily tasks, on helping you
                achieve goals in performing daily living skills such as bathing,
                grooming, dressing, and using the restroom on your own. Tasks
                that were once effortless can become challenging for older adult
                patients. Therapists critically assess their patient’s tasks,
                the occupations of their life, and create a customized treatment
                plan to achieve the patient-specific goals. <br /> <br />
                <SubTitleSection>
                  Our in-home service typically include: <br /> <br />
                </SubTitleSection>
                <List hasMarginLeft>
                  <li>
                    Customized treatment programs to increase your ability to
                    perform daily activities
                  </li>
                  <li>
                    Comprehensive home evaluations for adapting to your
                    situation
                  </li>
                  <li>
                    Assistive equipment recommendations and training on use
                  </li>
                  <li>
                    Guidance for family members, caregivers, and home health
                    aides
                  </li>
                </List>
              </TextDiv>
              <TextDiv>
                To keep you safe in the comfort of your home, we conduct a fall
                risk assessment of your environment to provide recommendations
                on how to improve safety.
                <br /> <br />
                <TherapyAdressDiv isWhite>
                  <TherapyTitle>Occupational Therapy addresses:</TherapyTitle>
                  <List hasTwoColumns>
                    <li>Dressing</li>
                    <li>Bathing</li>
                    <li>Grooming</li>
                    <li>Meal Preparation</li>
                    <li>Medication Management</li>
                    <li>Environmental Obstacles</li>
                    <li>Dementia-Related Limitations</li>
                    <li>Caregiver Stress</li>
                  </List>
                </TherapyAdressDiv>
              </TextDiv>
            </TextSection>
          </DesktopSection>
        </InternalWrapper>
      </OccupationalTherapySection>
      <div data-aos="fade" className="container">
        <SpeechTherapySection>
          <MobileSection hasNoBorderAndPaddingBottom={isSpeechOpen}>
            <DivTitleMobile>
              <TitleMobile>OCCUPATIONAL THERAPY</TitleMobile>
              <UnderLineTitle displayNone={!isSpeechOpen} />
            </DivTitleMobile>
            <MoreInfoButton
              isSmaller={isSpeechOpen}
              src={isSpeechOpen ? minus : plus}
              onClick={() => setSpeechOpen(!isSpeechOpen)}
            />
          </MobileSection>
          <GrayLine notDisplay={isSpeechOpen} />
          <DesktopSection display={isSpeechOpen}>
            <>
              <SubTitle displayNone={isSpeechOpen} alignStart>
                SPEECH-LANGUAGE PATHOLOGY
              </SubTitle>
              <UnderLineTitle displayNone={isSpeechOpen} />
            </>
            <TextSection>
              <TextDiv hasMarginRight>
                Our speech therapy services are designed to help you restore
                communication skills and abilities such as eating and
                swallowing, all from the comfort of home. Our speech therapists
                work with your physician to create a goal-oriented treatment
                plan aimed to increase independence and improve your quality of
                life. Speech-language pathologists focus on our patients’
                talking, cognition, chewing, and swallowing. A small area of the
                body that has a huge impact on life. An individual plan is
                created to address patient goals using <br /> <br /> <br />
                <SubTitleSection isBigger>
                  Our in-home service typically include: <br /> <br />
                </SubTitleSection>
                <List hasMarginLeft>
                  <li>
                    Evaluate current abilities for swallowing, communication,
                    skills and cognitive loss
                  </li>
                  <li>Create a care plan for swallowing problems</li>
                  <li>
                    Improve communication skills to positively impact safety and
                    quality of life
                  </li>
                  <li>
                    Enhance attention, memory, reasoning, safety awareness,
                    coping strategies, and problem solving
                  </li>
                </List>
              </TextDiv>
              <TextDiv>
                evidence-based treatment strategies to maximize patients’
                outcomes. SLPs collaborate with physical and occupational
                therapists to ensure carryover in the senior living community
                and the home environment.
                <TherapyAdressDiv>
                  <TherapyTitle>
                    Speech-Language Pathology addresses
                  </TherapyTitle>
                  <List hasTwoColumns>
                    <li>Speech</li>
                    <li>Language</li>
                    <li>Social Communications</li>
                    <li>Cognition</li>
                    <li>Chewing</li>
                    <li>Swallowing</li>
                  </List>
                </TherapyAdressDiv>
              </TextDiv>
            </TextSection>
          </DesktopSection>
        </SpeechTherapySection>
      </div>
      <PaymentDiv>
        <PaymentImage1366
          src={Payment1366}
          alt="Nurse of life therapy caring for elderly woman at home "
        />
        <PaymentImage1920
          src={Payment1920}
          alt="Nurse of life therapy caring for elderly woman at home "
        />
        <PaymentImage2800
          src={Payment2800}
          alt="Nurse of life therapy caring for elderly woman at home "
        />
        <PaymentImageMobile
          src={PaymentMobile}
          alt="Nurse of life therapy caring for elderly woman at home "
        />
        <PaymentTextDiv>
          <PaymentWrapper data-aos="fade">
            <PaymentContent>
              <PaymentTitle>PAYMENT OPTIONS</PaymentTitle>
              <UnderLineTitle isWhite />
              <PaymentText>
                Get in touch to learn more about our services. We accept:
              </PaymentText>
              <List hasMarginLeft>
                <PaymentItem>Medicare Part B</PaymentItem>
                <PaymentItem>Most PPO plans</PaymentItem>
                <PaymentItem>Long Term Care Insurance</PaymentItem>
                <PaymentItem>Private pay</PaymentItem>
              </List>
            </PaymentContent>
          </PaymentWrapper>
        </PaymentTextDiv>
      </PaymentDiv>
      <ContactFormContainer>
        <InternalWrapper data-aos="fade">
          <ContactSection>
            <SubTitle alignStart>GET IN TOUCH</SubTitle>
            <UnderLineTitle />
          </ContactSection>
          <ContactForm />
        </InternalWrapper>
      </ContactFormContainer>
    </Layout>
  )
}

export default Services
