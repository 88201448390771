import styled from "styled-components"

import { TABLET_740 } from "../../styles/globals/sizes"

import { RED_TITLE, WHITE, BLACK_GET_IN_TOUCH } from "../globals/colors"

export const AboutusContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const WhoWeAreSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 85px;
  margin-top: 81px;

  @media (max-width: ${TABLET_740}px) {
    margin-top: 40px;
  }
`

export const Title = styled.h1`
  color: ${RED_TITLE};
  font-size: 35px;
  line-height: 42px;
  font-weight: 600;

  @media (max-width: ${TABLET_740}px) {
    text-align: center;
  }
`

export const UnderLineTitle = styled.div`
  width: 113px;
  height: 4px;
  margin-top: 12px;
  background-color: ${({ isWhite }) => (isWhite ? WHITE : RED_TITLE)};

  @media (max-width: ${TABLET_740}px) {
    margin-top: 31px;
  }
`

export const Text = styled.div`
  margin-top: ${({ moreMarginTop }) => (moreMarginTop ? "54px" : "20px")};
  max-width: 1026px;
  padding: 0px 35px;
  text-align: -webkit-center;
  color: ${BLACK_GET_IN_TOUCH};
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;

  @media (max-width: ${TABLET_740}px) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const ImageDiv = styled.img`
  width: 100%;
  object-fit: fill;
  margin-top: 52px;

  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
`

export const ImageDivMobile = styled.img`
  display: none;

  @media (max-width: ${TABLET_740}px) {
    margin-top: 31px;
    width: 100vw;
    object-fit: fill;
    display: flex;
  }
`
