import styled from "styled-components"

import { TABLET_740 } from "../../styles/globals/sizes"

import { RED_TITLE } from "../globals/colors"

export const ContactFormContainer = styled.div`
  background: transparent linear-gradient(113deg, #dfdfdf 0%, #e8e8e8 100%) 0%
    0% no-repeat padding-box;
  padding-bottom: 60px;
  flex: 1;
  @media (max-width: ${TABLET_740}px) {
    height: auto;
  }
`

export const Title = styled.h1`
  font-size: 31px;
  line-height: 38px;
  color: ${RED_TITLE};

  @media (max-width: ${TABLET_740}px) {
    text-align: center;
  }
`

export const UnderLineTitle = styled.div`
  width: 113px;
  height: 4px;
  margin-top: 12px;
  background-color: ${RED_TITLE};

  @media (max-width: ${TABLET_740}px) {
    margin-top: 0;
  }
`

export const ContactDiv = styled.div`
  padding-top: 79px;
  margin-bottom: 30px;

  @media (max-width: ${TABLET_740}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 72px;
  }
`

export const ModalDescription = styled.p`
  font: normal normal normal 16px/24px Roboto;
  letter-spacing: 0px;
`

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 5%;
`
