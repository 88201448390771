import styled, { css } from "styled-components"

import {
  TABLET_992,
  DESKTOP_1024,
  TABLET_740,
  DESKTOP_1366,
} from "../../styles/globals/sizes"

import { WHITE, RED_TITLE, GRAY_UNDER_TITLE } from "../../styles/globals/colors"

export const HeroContainer = styled.div`
  background-image: url(${props => props.image.desktop1920});
  height: 417px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: 100% auto;
  background-position-x: right;
  margin-top: 90px;

  @media (max-width: 1920px) {
    ${({ isFromServices }) =>
      !isFromServices
        ? css`
            background-image: url(${props => props.image.desktop});
          `
        : css`
            background-size: auto auto;
          `}
  }

  @media (max-width: ${DESKTOP_1366}px) {
    background-size: cover;
  }

  @media (max-width: ${TABLET_740}px) {
    background-image: url(${props => props.image.mobile});
    width: 100%;
    background-size: cover;
    margin-top: 78px;
  }
`

export const Title = styled.h2`
  font-size: 42px;
  line-height: 50px;
  font-weight: 600;
  color: ${RED_TITLE};
  @media (max-width: ${TABLET_740}px) {
    font-weight: 500;
    color: ${WHITE};
  }
`

export const Text = styled.div`
  font-size: 19px;
  line-height: 24px;
  font-weight: 300;
  color: ${GRAY_UNDER_TITLE};

  @media (max-width: ${TABLET_740}px) {
    color: ${WHITE};
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 90%;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 90%;
  }
`

export const TextDiv = styled.div`
  text-align: center;
`
