import styled from "styled-components"

import { RED_TITLE } from "../../styles/globals/colors"

export const Container = styled.div`
  text-align: center;

  .slick-dots li button:before {
    color: ${RED_TITLE};
  }
`
