import styled, { css } from "styled-components"

import ContactBackground from "../../images/contact_background.jpg"

import {
  TABLET_992,
  TABLET_740,
  DESKTOP_1024,
  DESKTOP_1366,
  DESKTOP_1920,
} from "../../styles/globals/sizes"

import {
  RED_TITLE,
  GRAY_SECTION,
  BLACK_GET_IN_TOUCH,
  GRAY_LINES_SERVICES,
  WHITE,
} from "../globals/colors"

export const PhysicalTherapySection = styled.div`
  margin: 72px 0 95px 0;

  @media (max-width: ${TABLET_740}px) {
    margin: 0;
  }
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  @media (max-width: ${TABLET_740}px) {
    flex-direction: column;
  }
`

export const TextDiv = styled.div`
  width: 50%;
  font-size: 16px;
  line-height: 29px;
  margin-right: ${({ hasMarginRight }) => hasMarginRight && "34px"};
  font-weight: 300;

  @media (max-width: ${TABLET_740}px) {
    width: 100%;
  }
`

export const SubTitleSection = styled.div`
  font-size: ${({ isBigger }) => (isBigger ? "25px" : "20px")};
  line-height: 24px;
  font-weight: 600;
  color: ${RED_TITLE};
`

export const TherapyAdressDiv = styled.div`
  background-color: ${({ isWhite }) => (isWhite ? WHITE : GRAY_SECTION)};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 21px;
  opacity: 1;
  padding: 27px 0 25px 29px;
  margin-bottom: 28px;
  margin-top: ${({ isWhite }) => (isWhite ? "0" : "40px")};

  @media (max-width: ${TABLET_740}px) {
    background-color: ${GRAY_SECTION};
  }
`

export const TherapyTitle = styled.div`
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 21px;
  color: ${BLACK_GET_IN_TOUCH};
`

export const ListsDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${TABLET_740}px) {
    flex-direction: column;
  }
`

export const OccupationalTherapySection = styled.div`
  background-color: ${GRAY_SECTION};
  padding: 4px 0 80px 0;

  @media (max-width: ${TABLET_740}px) {
    padding: 0;
    background-color: ${WHITE};
  }
`

export const SpeechTherapySection = styled.div`
  margin-bottom: 92px;

  @media (max-width: ${TABLET_740}px) {
    margin-bottom: 0;
  }
`

export const DesktopSection = styled.div`
  margin-top: 71px;

  @media (max-width: ${TABLET_740}px) {
    display: ${({ display }) => (display ? "flex" : "none")};
    margin-top: 0;
  }
`

export const List = styled.ul`
  list-style: disc;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? "16px" : "0")};

  li::marker {
    font-size: 1.2em;
  }
  li {
    font-size: 16px;
    line-height: 29px;
  }

  ${({ hasTwoColumns }) =>
    hasTwoColumns &&
    css`
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
      list-style-position: inside;
    `}
`

export const MobileSection = styled.div`
  display: none;

  @media (max-width: ${TABLET_740}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 45px 0;

    ${({ hasNoBorderAndPaddingBottom }) =>
      hasNoBorderAndPaddingBottom &&
      css`
        padding: 45px 0 0 0;
        border-bottom: none;
      `}
  }
`

export const TitleMobile = styled.div`
  color: ${RED_TITLE};
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 2px;
`

export const MoreInfoButton = styled.img`
  width: 24px;
  height: 24px;

  ${({ isSmaller }) =>
    isSmaller &&
    css`
      margin-top: 13px;
      height: 2px;
    `}
`

export const LargeText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;

  @media (max-width: ${TABLET_740}px) {
    display: ${({ display }) => (display ? "flex" : "none")};
  }
`

export const DivTitleMobile = styled.div`
  display: flex;
  flex-direction: column;
`

export const GrayLine = styled.div`
  display: none;

  @media (max-width: ${TABLET_740}px) {
    height: 1px;
    width: 100%;
    background-color: ${GRAY_LINES_SERVICES};
    position: absolute;
    right: 0;
    display: ${({ notDisplay }) => (notDisplay ? "none" : "flex")};
  }
`

export const ContactFormContainer = styled.div`
  background-image: url(${ContactBackground});
  height: 439px;
  background-size: cover;
  background-position-x: right;
  flex: 1;
  padding-bottom: 59px;
`

export const ContactSection = styled.div`
  padding-top: 65px;

  @media (max-width: ${TABLET_740}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    margin-bottom: 33px;
  }
`

export const PaymentDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
  }
`

export const PaymentTextDiv = styled.div`
  background: transparent
    linear-gradient(91deg, var(--unnamed-color-ec3e2c) 0%, #ed67a6 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(91deg, #ec3e2c 0%, #ed67a6 100%) 0% 0%
    no-repeat padding-box;
  width: 50%;
  display: flex;
  align-items: center;
  padding-top: 25px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    padding-top: 0px;
  }
`

export const PaymentTitle = styled.div`
  color: ${WHITE};
  font-size: 31px;
  line-height: 40px;
  font-weight: 600;

  @media (max-width: ${TABLET_740}px) {
    margin: 40px 0 30px 0;
  }
`

export const PaymentText = styled.div`
  color: ${WHITE};
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 20px;

  @media (max-width: ${TABLET_740}px) {
    margin: 31px 0 22px 0;
  }
`

export const PaymentItem = styled.li`
  color: ${WHITE};
  font-size: 20px;
  line-height: 44px;
  font-weight: 500;
  margin-left: 4px;
  margin-bottom: 5px;
`

export const PaymentImageMobile = styled.img`
  display: none;

  @media (max-width: ${TABLET_740}px) {
    display: flex;
  }
`

export const PaymentImage1366 = styled.img`
  display: none;

  @media (max-width: ${DESKTOP_1366}px) {
    width: 50%;
    display: flex;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }

  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
`

export const PaymentImage1920 = styled.img`
  display: none;

  @media (max-width: ${DESKTOP_1920}px) {
    width: 50%;
    display: flex;
  }

  @media (max-width: ${DESKTOP_1366}px) {
    display: none;
  }
`

export const PaymentImage2800 = styled.img`
  width: 50%;

  @media (max-width: ${DESKTOP_1920}px) {
    display: none;
  }
`

export const PaymentWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin-left: 0;

  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 90%;
  }

  @media (max-width: ${TABLET_992}px) {
    max-width: 90%;
  }
`

export const PaymentContent = styled.div`
  margin-left: 74px;
  margin-bottom: 30px;

  @media (max-width: ${TABLET_992}px) {
    margin-left: 40px;
    margin-top: 59px;
  }
`
